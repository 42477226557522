
import {  Flex } from "theme-ui"
import RemoveProduct from "../components/Cart/Buttons/RemoveProduct"
import CheckoutButton from "../components/Cart/Buttons/CheckoutButton"
import { Link } from "gatsby"
import React from "react"
import Store from "../StoreContext/index"
import { getTotalPrice } from "../components/Cart/HelperFns/index"
import styled from "@emotion/styled"
import * as styles from "./cart.module.scss"


import { v4 as uuidv4 } from "uuid"
import { getSrc } from "gatsby-plugin-image"

var { useContext } = React
//this is the /cart page
const Cart = () => {
	var [store, setStore] = useContext(Store)
	var { added } = store

	const changeItemAmount = (e, i) => {
		// convert the selected value into a number
		var selectValue = parseInt(e.target.value, 10)
		//  select the right item to update
		var selectedItem = added[i]
		setStore((curStore) => {
			// i represents the items index.
			// find that item within the Global Store Added array
			// change it's quantity to whatever is selected in the drop down fieled
			// e.g. e.target.value
			selectedItem.chosenVariant.quantity = selectValue
			// set the local storage:
			localStorage.setItem(`added`, JSON.stringify(added))
			// return the newly updated cart
			return (store = {
				...curStore,
				added,
			})
		})
	}
	return (
		<div className={styles.cartWrapper}>
			<StyledLabelContainer>
				<StyledLabel>Cart</StyledLabel>
			</StyledLabelContainer>

			{added.length > 0 ? (
				getListItems(added, changeItemAmount)
			) : (
				<div
					style={{
						display: "flex",
						flexDirection: "column-reverse",
						marginRight: "2vw",
					}}
				>
					<div
						style={{
							alignSelf: "flex-start",
							marginLeft: "2vw",
							padding: "1rem 0 1rem 1rem",
						}}
					>
						<p className={styles.emptyShoppingBag}>Shopping bag is empty</p>
						<Link to="/">
							<button className={styles.cartBtn}>Back</button>
						</Link>
					</div>
				</div>
			)}

			{added.length > 0 && (
				<div
					style={{
						display: "flex",
						marginRight: "2vw",
						padding: "1rem 0 1rem 1rem ",
						alignContent: "center",
						alignItems: "center",
					}}
				>
					<div style={{ alignSelf: "flex-end" }}>
						<Link to="/">
							<button className={styles.cartBtn}>Back</button>
						</Link>
					</div>

					<CheckoutButton>
						<button className={`${styles.cartBtn} ${styles.checkoutBtn}`}>
							Checkout
						</button>
					</CheckoutButton>
					<div className={styles.styledPriceContainer}>
						<div>Total: {getTotalPrice(added)} €</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default Cart

function getListItems(added, changeItemAmount) {
	return added.map((item, i) => {
		let imgSrc = getSrc(item.images[0])
		return (
			<>
				<div className={styles.container} key={uuidv4()}>
					<div className={styles.productImgContainer}>
						{item.images[0].localFile ? (
	
							//What is thissss ._.
							<img src = {imgSrc}/>
						) : (
							<img
								className={styles.lineImageSafari}
								src={imgSrc}
								alt=""
							/>
						)}
					</div>

					<div className={styles.quantityContainer}>
						<p className={styles.quantity}>
							{item.chosenVariant.quantity} x EUR {item.chosenVariant.price}
						</p>
						<select
							// make sure it's value equals the items quantity
							// value={item.quantity}
							// use defaultValue for theme-ui select:
							style={{
								borderRadius: "0",
								marginLeft: "1rem",
								marginTop: "-2px",
								width: "2.5rem",
								border: "1px solid black",
								height: "min-content",
								fontSize: "15px",
							}}
							defaultValue={item.chosenVariant.quantity}
							onChange={(e) => {
								// this function get's passed the event and the index of the item
								changeItemAmount(e, i)
							}}
						>
							<option value={1}>1</option>
							<option value={2}>2</option>
							<option value={3}>3</option>
							<option value={4}>4</option>
							<option value={5}>5</option>
						</select>
					</div>

					<div className={styles.titleContainer}>
						<p className={styles.title}>{item.title}</p>
						{renderVariantTitle(item)}
						<RemoveProduct shopifyId={item.chosenVariant.shopifyId} />
					</div>
				</div>

			</>
		)
	})
}

const renderVariantTitle = (item) => {
	return item.variants.length > 1 ? (
		<li >{item.chosenVariant.title}</li>
	) : null
}

const StyledLabelContainer = styled.div`
	border-bottom: 1px solid black;
	padding: 1rem;
`
const StyledLabel = styled.h1`
	font-size: 18px;
`

const StyledRowContainer = styled.div`
	flex-basis: 100%;
	display: flex;
	flex-flow: row wrap;
	padding-top: 1;
	padding-bottom: 1;
	align-items: center;
	margin-bottom: 0.2rem;
	margin-top: 0.2rem;
	justify-content: space-between;
`

const StyledBuyButtonContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin: 1rem 3vw 0;
	flex-direction: row;
	align-items: center;
	@media (max-width: 768px) {
		margin: 0;
		border: 1px solid black;
		height: 15vh;
	}
`

const StyledTotalPrice = styled.p`
	font-size: 18px;
	justify-content: center;
	align-items: center;
	align-content: center;
	vertical-align: text-bottom;
	display: flex;
	width: max-content;
`
