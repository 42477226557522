// extracted by mini-css-extract-plugin
export var cartBtn = "cart-module--cartBtn--IGelt";
export var cartWrapper = "cart-module--cartWrapper---Qm7O";
export var checkoutBtn = "cart-module--checkoutBtn--PSMSB";
export var container = "cart-module--container--25dsr";
export var continueShoppingBtn = "cart-module--continueShoppingBtn--lNyzQ";
export var emptyShoppingBag = "cart-module--emptyShoppingBag--3vs0o";
export var lineImage = "cart-module--lineImage--SZut-";
export var lineImageSafari = "cart-module--lineImageSafari--+JOVS";
export var productImgContainer = "cart-module--productImgContainer--4eaAx";
export var productThumbnail = "cart-module--productThumbnail--s8PDx";
export var quantity = "cart-module--quantity--sk7+c";
export var quantityContainer = "cart-module--quantityContainer--SEs5v";
export var rowContainer = "cart-module--rowContainer--cEER-";
export var styledPriceContainer = "cart-module--styledPriceContainer--4hfMP";
export var title = "cart-module--title--KWlDB";
export var titleContainer = "cart-module--titleContainer--HIT6A";
export var variantTitle = "cart-module--variantTitle--5jiEQ";